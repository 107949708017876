import { Image, Select, Tag } from "antd";
import { useEffect, useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { FaHammer } from "react-icons/fa6";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  SoldLotsFavorites,
  addFavourite,
  deleteFavourite,
  downloadExcelFile,
  downloadExcelFileWitCatagroy,
  downloadFavExcelFile,
  getCategory,
  getFilterlots,
  getLotDetails,
  getSoldLots,
} from "../../Api/lotsapi";
import {
  isAllradyLoginAction,
  logOutAction,
} from "../../Redux/Actions/Actions";
import { getDateTime, handleDownload } from "../../Redux/Constants";
import FixComponents from "../FixComponents";
import LotDetailPopup from "../LotDetailPopup/LotDetailPopup";
import { BiSolidDownload } from "react-icons/bi";
import { log } from "util";
import { Pagination } from "antd";
import Pusher from "pusher-js";
import CountdownComponent from "../Countdown/CountdownComponent";
import loading from "../../assets/images/Group.svg";
import moment from "moment";
import { CiCalendar } from "react-icons/ci";

function Sold(props) {
  const [soldLots, setSoldLots] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [apiLoading, setApiLoding] = useState(false);
  const [open, setOpen] = useState(false);
  const [lotDetails, setLotDetails] = useState(null);
  const [favourite, setFavourite] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(10);
  const [currentSoldLots, setCurrentSoldLots] = useState(null);
  const [selectValue, setSelectValue] = useState("all");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [category, setCategory] = useState([]);

  //useEffect for pagination
  useEffect(() => {
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;

    setCurrentSoldLots(
      Array.isArray(soldLots)
        ? soldLots.slice(indexOfFirstCard, indexOfLastCard)
        : []
    );
  }, [currentPage, cardsPerPage, soldLots]);

  const fetchSoldLots = () => {
    // setApiLoding(true);
    // setSoldLots([]);
    getSoldLots(userDetails?.id).then((res) => {
      if (res.status == 200 && res.data?.success === true) {
        setFavourite(false);
        setSoldLots(res.data?.SoldLots);
        setApiLoding(false);
      } else {
        // setSoldLots([]);
        setApiLoding(false);
      }
    });
  };

  useEffect(() => {
    if (userDetails?.id) {
      fetchSoldLots();
      fetchCategory();
    }
  }, [userDetails]);

  const fetchLotDetails = (id) => {
    setApiLoding(true);
    getLotDetails(id).then((res) => {
      if (res.status == 200) {
        setLotDetails(res.data);
      } else {
        setApiLoding(false);
      }
    });
  };
  useEffect(() => {
    if (
      !props.loginUserDetailsReducer.loginUserDetails &&
      localStorage.getItem("UserDetails") &&
      localStorage.getItem("UserDetails") !== ""
    ) {
      props.isAllradyLoginAction(
        JSON.parse(localStorage.getItem("UserDetails"))
      );
    } else if (!props.loginUserDetailsReducer.loginUserDetails) {
      // navigate('/')
    } else if (props.loginUserDetailsReducer.loginUserDetails) {
      setUserDetails(props.loginUserDetailsReducer.loginUserDetails);
    }
    if (
      localStorage.getItem("LoginDate") &&
      localStorage.getItem("LoginDate") != new Date().toLocaleDateString()
    ) {
      onLogOut();
    }
  }, [props?.loginUserDetailsReducer]);
  const onLogOut = () => {
    Navigate("/");
    props.logOutAction();
    window.location.reload();
  };

  const fetchAllFavouriteLots = () => {
    setSelectValue("all");
    setApiLoding(true);
    setSoldLots([]);
    SoldLotsFavorites(userDetails?.id).then((res) => {
      if (res.status == 200 && res.data?.success === true) {
        setFavourite(true);
        setSoldLots(res.data?.Fav_lots);
        setApiLoding(false);
      } else {
        setSoldLots([]);
        setApiLoding(false);
      }
    });
  };

  const downloadExcel = () => {
    setApiLoding(true);
    downloadExcelFile("Sold").then((res) => {
      if (res.status === 200) {
        handleDownload(res.data?.file_url);
        setApiLoding(false);
      } else {
        setApiLoding(false);
      }
    });
  };
  const downloadFavExcel = () => {
    setApiLoding(true);
    downloadFavExcelFile(userDetails?.id, "Sold").then((res) => {
      if (res.status === 200) {
        handleDownload(res.data?.file_url);
        setApiLoding(false);
      } else {
        setApiLoding(false);
      }
    });
  };

  useEffect(() => {
    if (selectValue) {
      setSelectedCategory(category.find((item) => item.id === selectValue));
    }
  }, [selectValue]);

  const deleteFavouriteLot = (user_id, lot_id) => {
    setApiLoding(true);
    setSoldLots([]);
    deleteFavourite(user_id, lot_id).then((res) => {
      if (res.status == 200 && res.data?.success === true) {
        fetchSoldLots();
        setApiLoding(false);
      } else {
        fetchSoldLots();
        setApiLoding(false);
      }
    });
  };

  useEffect(() => {
    Pusher.logToConsole = false;
    var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "ap2",
    });

    var channel = pusher.subscribe("lot-change");
    channel.bind("lot.successful", function (data) {
      fetchSoldLots();
    });

    return () => {
      pusher.unsubscribe("lot-change");
    };
  }, []);

  const addFavouriteLot = (user_id, lot_id) => {
    setApiLoding(true);
    setSoldLots([]);
    addFavourite(user_id, lot_id).then((res) => {
      if (res.status == 200 && res.data?.success === true) {
        fetchSoldLots();
        setApiLoding(false);
      } else {
        fetchSoldLots();
        setApiLoding(false);
      }
    });
  };
  const fetchCategory = () => {
    getCategory().then((res) => {
      if (res.status === 200 && res.data?.success == true) {
        setCategory(res?.data?.categories);
      }
    });
  };

  const fetchFilterLots = (cId) => {
    setApiLoding(true);
    setSoldLots(null);
    getFilterlots(userDetails?.id, cId, "Sold").then((res) => {
      if (res.status === 200 && res.data?.success) {
        setSoldLots(res.data?.userLots);
        setApiLoding(false);
      } else {
        setApiLoding(false);
      }
    });
  };
  const onChangeSelect = (val) => {
    setFavourite(false);
    setSelectValue(val);
    if (val === "all") {
      fetchSoldLots();
    } else {
      fetchFilterLots(val);
    }
  };

  const downloadExcelWithCategroy = () => {
    setApiLoding(true);
    downloadExcelFileWitCatagroy(
      userDetails?.id,
      selectedCategory?.id,
      "Sold"
    ).then((res) => {
      if (res.status === 200) {
        handleDownload(res.data?.file_url);
        setApiLoding(false);
      } else {
        setApiLoding(false);
      }
    });
  };
  return (
    <>
      <LotDetailPopup
        data={open}
        lot={lotDetails}
        onCancel={() => {
          setOpen(false);
          setLotDetails(null);
        }}
      />
      <div className="">
        <div className="col-12">
          <div className="catagroy">
            <span>
              <h4>Search by categories:</h4>
            </span>
            <Select
              showSearch
              className="select-section"
              placeholder="Select a category"
              optionFilterProp="children"
              onChange={onChangeSelect}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              value={selectValue}
            >
              <option key={"all"} value={"all"} label={"all"}>
                All
              </option>
              {category?.map((item) => {
                return (
                  <>
                    <option key={item?.id} value={item?.id} label={item?.title}>
                      {item?.title}
                    </option>
                  </>
                );
              })}
            </Select>
          </div>
          <div className="Favourite_section">
            <button
              type="button"
              className="btn Favourite_btn"
              style={{ background: favourite ? "black" : "" }}
              onClick={() => {
                favourite ? fetchSoldLots() : fetchAllFavouriteLots();
              }}
            >
              <AiOutlineStar
                // color="white"
                className="mr-2"
                size={20}
              />
              OMT
            </button>
            {category?.map((item) => {
              return (
                <>
                  <button
                    key={item?.id}
                    type="button"
                    className="btn Favourite_btn"
                    onClick={() => {
                      setFavourite(false);
                      setSelectValue(item?.id);
                      onChangeSelect(item?.id);
                    }}
                    style={{
                      background: selectValue === item?.id ? "black" : "",
                    }}
                  >
                    {item?.title}
                  </button>
                </>
              );
            })}
          </div>
        </div>
        <div className="row justify-content-center">
          <div
            className="card p-0 m-2 w-100"
            style={{ backgroundColor: "rgb(239, 239, 239)" }}
          >
            <div className="card-header bg-white">
              <div
                className="live_LotsDetails_header p-2"
                style={{ color: "black" }}
              >
                {/* <div style={{ width: "5%" }}>
                  <button
                    type="button"
                    className="btn Favourite_btn"
                    style={{ background: favourite ? "red" : "" }}
                    onClick={() => {
                      favourite ? fetchSoldLots() : fetchAllFavouriteLots();
                    }}
                  >
                    <AiOutlineStar
                      // color="white"
                      className="mr-2"
                      size={20}
                    />
                    OMT
                  </button>
                </div> */}
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  {selectedCategory ? selectedCategory?.title : "All"} -{" "}
                  {soldLots?.length} - Sold Lots
                  {soldLots?.length > 0 ? (
                    <div>
                      <button
                        type="button"
                        className="btn Favourite_btn ml-4"
                        style={{
                          padding: " 0.325rem 0.25rem",
                          backgroundColor: "green",
                        }}
                        onClick={() => {
                          selectedCategory != null
                            ? downloadExcelWithCategroy()
                            : favourite
                            ? downloadFavExcel()
                            : downloadExcel();
                        }}
                      >
                        <BiSolidDownload className="mr-1" size={24} />
                        Download Excel
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {soldLots?.length > 0 ? (
              currentSoldLots?.map((lot) => {
                return (
                  <div
                    className="card card_section"
                    onClick={(e) => {
                      setOpen(true);
                      setLotDetails(lot?.id);
                      // fetchLotDetails(lot?.id);
                    }}
                  >
                    <div className="card-body p-0">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item p-0">
                          <div className={
                                lot?.uploadlotpicture
                                  ? "description-section"
                                  : "w-100"
                              }>
                            <div className="w-100">
                              <div className="live_Lots_title p-3">
                                <div>{lot.title}</div>
                                {/* <div>{lot.categories?.title}</div> */}
                              </div>
                              <div className="lots_details_mobile">
                                <div>
                                  {" "}
                                  {lot?.customers?.length > 0 || favourite ? (
                                    <AiFillStar
                                      color="#e5c04c"
                                      size={35}
                                      className="mx-4"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        deleteFavouriteLot(
                                          userDetails?.id,
                                          lot?.id
                                        );
                                      }}
                                    />
                                  ) : (
                                    <AiOutlineStar
                                      color="rgb(114 178 188)"
                                      size={35}
                                      className="mx-4"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        addFavouriteLot(
                                          userDetails?.id,
                                          lot?.id
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                <div>
                                  <Tag className="tag_text">
                                    Lot No:{lot.id}
                                  </Tag>
                                  <Tag className="tag_text">
                                    Qty: {lot.Quantity} mt
                                  </Tag>
                                  <Tag className="tag_text">
                                    ₹
                                    {lot?.bids?.length > 0
                                      ? parseInt(
                                          lot?.bids[0]?.max_bid
                                        ).toLocaleString()
                                      : parseInt(lot?.Price).toLocaleString()}
                                    /mt &nbsp;&nbsp;
                                    {/* <img src={loading} style={{ width: "15px" }} /> */}
                                  </Tag>
                                  {/* <CountdownComponent lot={lot} /> */}
                                  {/* <Tag
                                  style={{
                                    backgroundColor: "#FFD700",
                                  }}
                                  className="tag_text"
                                >
                                  <FaHammer className="mr-2" />
                                  Bid Now!
                                </Tag>
                                <Tag
                                  style={{
                                    backgroundColor: "rgb(241 214 228)",
                                  }}
                                  className="tag_text"
                                >
                                  Ended At{" "}
                                  {moment(lot.EndDate).format(
                                    "DD-MMM-YY h:mma"
                                  )}
                                </Tag> */}
                                  <Tag className="tag_text">
                                    SOLD TO HIGHEST BIDDER
                                  </Tag>
                                  <Tag
                                    style={{
                                      backgroundColor: "rgb(241 241 241)",
                                    }}
                                    className="tag_text"
                                  >
                                    STARTED AT{" "}
                                    {moment(lot.StartDate).format(
                                      "DD-MMM-YY h:mma"
                                    )}
                                  </Tag>
                                  {/* <br /> */}
                                  <Tag
                                    style={{
                                      backgroundColor: "rgb(240 225 206)",
                                    }}
                                    className="tag_text"
                                  >
                                    {lot?.materialLocation
                                      ? lot?.materialLocation.toUpperCase()
                                      : ""}
                                  </Tag>
                                  <Tag
                                    style={{
                                      backgroundColor: "rgb(240 225 206)",
                                    }}
                                    className="tag_text"
                                  >
                                    {lot?.make_in
                                      ? "MADE IN  " + lot?.make_in.toUpperCase()
                                      : ""}
                                  </Tag>
                                  {/* <Tag
                                  style={{
                                    backgroundColor: "rgb(155 220 226)",
                                  }}
                                  className="tag_text"
                                >
                                  READY STOCK!
                                </Tag>
                                <Tag
                                  style={{
                                    backgroundColor: "rgb(221 255 215)",
                                  }}
                                  className="tag_text"
                                >
                                  BID N WIN
                                </Tag> */}

                                  <Tag
                                    style={{
                                      backgroundColor: "rgb(155 220 226)",
                                    }}
                                    className="tag_text"
                                  >
                                    Participation Fee:{" "}
                                    {parseInt(
                                      lot.participate_fee
                                    ).toLocaleString()}{" "}
                                   
                                  </Tag>

                                  <Tag
                                    style={{
                                      backgroundColor: "rgb(244 243 237)",
                                    }}
                                    className="tag_text"
                                  >
                                    Start Rs{" "}
                                    {parseInt(lot?.Price).toLocaleString()}{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;+
                                    {lot?.bids?.[0]?.max_bid
                                      ? parseInt(
                                          lot?.bids?.[0]?.max_bid-lot?.Price
                                        ).toLocaleString()
                                      : 0}
                                  </Tag>
                                </div>
                              </div>
                              <div className="Lot-Bottom-section">
                                <div className="detail-section">
                                  <CiCalendar size={28} />
                                  <h3>Ended At:</h3>
                                  <p>{moment(lot.EndDate).format("h:mma")}</p>
                                </div>{" "}
                                <CountdownComponent lot={lot} />
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              lot?.uploadlotpicture ? "Image-secion" : ""
                            }
                          >
                            {lot?.uploadlotpicture ? (
                              <Image
                                src={
                                  new URL(
                                    `https://admin.steel24.in/LotImages/${lot?.uploadlotpicture}`
                                  )
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div className="main_card_body">
                  <FixComponents
                    comp={!apiLoading && soldLots != null ? "NoData" : ""}
                  />
                </div>
              </>
            )}
            <div className="d-flex justify-content-center p-1 bg-white">
              <Pagination
                current={currentPage}
                pageSize={cardsPerPage}
                total={soldLots?.length}
                showTotal={(total) => `Total ${total} items`}
                onChange={(page) => {
                  setCurrentPage(page);
                }}
                onShowSizeChange={(current, size) => {
                  setCardsPerPage(size);
                  setCurrentPage(current);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        open={open}
        footer={null}
        onCancel={() => {
          setLotDetails(null)
          setOpen(false);
        }}
        className="w-100"
      >
        {lotDetails ? (
          <>
            <div className="col-12 p-0">
              <div className=" p-0 m-4">
                <div
                  className="col-12 popup_lot_details"
                  style={{ background: lotDetails?.lot?.lot_status==="Sold"?"green":"" }}
                >
                  <div className="col-4 Right_div">
                    <div className="image_div">
                      <img src={appLogo} />
                    </div>
                    <div className="lot_status">Lot {lotDetails?.lot?.id} is{lotDetails?.lot?.lot_status==="Sold"?" sold":" not sold"}</div>
                  </div>
                  <div className="col-8 p-0">
                    <table id="example" className="table">
                      <colgroup>
                        <col style={{ width: "1%" }} />
                      </colgroup>
                      <tbody className="table_body">
                        <tr style={{ background: "#258EC7" }}>
                          <td>
                            JSW Steel Coated Products Ltd.
                            <br />
                            Plan: {lotDetails?.lot?.Plant}
                          </td>
                        </tr>
                        <tr>
                          <td>Lot No: {lotDetails?.lot?.id} &nbsp;Qty: {lotDetails?.lot?.Quantity} mt</td>
                        </tr>
                        <tr>
                          <td>Last Bid: Rs {lotDetails?.maxbid?.amount?lotDetails?.maxbid?.amount:0}</td>
                        </tr>
                        <tr>
                          <td>Expired Date: {lotDetails?.lot?.EndDate}</td>
                        </tr>
                        <tr>
                          <td>This Lot has been sold</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="col-12 popup_lot_details_bottom"
                  style={{ background: lotDetails?.lot?.lot_status==="Sold"?"green":"" }}
                >
                  COATED BY JSW-COATED-GL Coil Thk:0.7-0.8mm.Wid:1000mm Grade:
                  S1
                </div>
              </div>
            </div>
            <div className="col-12 p-0">
              <div className=" p-0 m-4">
                <div className="card-header popup_LotsDetails_header">
                  Terms & conditions: Please read the following carefully and
                  abide by them.
                </div>
                <div className="card-body p-0">
                  <ul className="">
                    <li className=" popup_terms_condition">
                      <div style={{ width: "10%" }}>Payment Terms:</div>
                      <div className="div_left">
                        100% Advance and EMD at Rs. 2000 per tone will be
                        charged for won but upaid lots.
                      </div>
                    </li>
                    <li className=" popup_terms_condition">
                      <div style={{ width: "10%" }}>Price Basis:</div>
                      <div className="div_left">
                        Ex-works (Freight & GST extra)
                      </div>
                    </li>
                    <li className=" popup_terms_condition">
                      <div style={{ width: "10%" }}>Taxes and Duties:</div>
                      <div className="div_left">
                        Prices are exclusive of GST, and Shall also be
                        applicable on freight. GST will be charged as per
                        Govt.norms
                      </div>
                    </li>
                    <li className=" popup_terms_condition">
                      <div style={{ width: "10%" }}>Commercial Terms:</div>
                      <div className="div_left">
                        1) Material to be lifted strictly within 10 days.
                        Discounts Rs.1000/pmt if Payment recevied within 3 days,
                        this discount is applicable for customers whose previous
                        payment and despatch are clear.(Working Days). # As per
                        our terms and conditions, if the customer doesn't lift
                        the material in the given 10 working days and if they
                        want to lift the material beyond those 10 days we
                        provide a 5 days Grace period by charging an extea
                        1000/pmt. And further than that the lot stands cancelled
                        and you will be charged Rs.2000/-pmt (asEMD) 2) if any
                        small lot purchased by customer then it is his
                        responsibility to lift the lot or pay full truck load
                        frieght (full truck load frieght i.e intil 25-30mt )
                        delivery:self lifting. EMD @2000 per metric ton will
                        bedeposited by all successfully bidder within three
                        working days. This amount will be adjust in the last lot
                        .3) TCS om"Scrape/Triming" as per IT act 1961 will be
                        applicable. 4) All material on Direct despatch basis .5)
                        Note delivery/Lifting will not be optional (party
                        truck/company truck),Customer has to choose any one that
                        will applicable Till March 2024, in case of party
                        truck/Self Lifting LR copy required.
                      </div>
                    </li>
                    <li className=" popup_terms_condition">
                      <div style={{ width: "10%" }}>Test Certificate:</div>
                      <div className="div_left">Not-Applicable</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12 p-0">
              <div className=" p-0 m-4">
                <div className="card-header live_LotsDetails_header">
                  The Details of Material in this lot : # {lotDetails?.lot?.id}
                </div>

                <table
                  id="example"
                  className="table table-striped table-bordered"
                >
                  <colgroup>
                    <col style={{ width: "1%" }} />
                    <col style={{ width: "1%" }} />
                    <col style={{ width: "1%" }} />
                    <col style={{ width: "1%" }} />
                    <col style={{ width: "1%" }} />
                    <col style={{ width: "1%" }} />
                    <col style={{ width: "1%" }} />
                  </colgroup>
                  <thead className="table_head">
                    <tr>
                      <th>Product</th>
                      <th>Thickness</th>
                      <th>Width</th>
                      <th>Length</th>
                      <th>Weight</th>
                      <th>Grade</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody className="table_body">
                  {lotDetails?.materialList?.map((material) => {
                      return (
                        <tr>
                          <td>{material?.Product}</td>
                          <td>{material?.Thickness}</td>
                          <td>{material?.Width}</td>
                          <td>{material?.Length}</td>
                          <td>{material?.Weight}</td>
                          <td>{material?.Grade}</td>
                          <td>{material?.Remark}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <>
            <FixComponents
              comp={!apiLoading && lotDetails != null ? "NoData" : ""}
            />
          </>
        )}
      </Modal> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getUpCommingLotsListReducer: state.getUpCommingLotsListReducer,
    loginUserDetailsReducer: state.loginUserDetailsReducer,
  };
};
const mapDispatchtoProps = {
  logOutAction: () => logOutAction(),
  isAllradyLoginAction: (details) => isAllradyLoginAction(details),
};
export default connect(mapStateToProps, mapDispatchtoProps)(Sold);
